<template>
	<div class="personBox">
		<div class="iconBtn" v-if="userInfo && userInfo.superUserId" @click="handleLogout">
			{{ $t('personal.loginOut') }}
		</div>
		<div class="iconBtn" v-else @click="handleLogin">
			{{ $t('personal.loginBtn') }}
		</div>
		<div class="cardBox">
			<div class="avatar">
				<img v-if="userInfo.picture" :src="userInfo.picture" :onerror="imgError">
				<img v-else src="~@/assets/images/common/avatar_temp.png" />
			</div>
			<div class="vipIcon">
				<span style="font-size: 0.66rem;" v-if="vipLevel == 'vip0'" >
					<router-link style="color: #f4f4f4;" to="/vipInfo">{{ $t('personal.commonUser') }}</router-link>
				</span>
				<span v-else>
					<router-link style="color: #f4f4f4;" to="/vipInfo">{{vipLevel}}</router-link>
				</span> 
			</div>
			<div class="vipDetail">
				<router-link to="/vipInfo">{{ $t('personal.vipDetail') }}＞</router-link>
			</div>
			<van-cell center :label="`${$t('personal.googleEmail')}：${userInfo.email || '***'}`" is-link to="/infos">
				<template #title>
				    <span class="titleLabel">{{ $t('personal.platId') }}：</span>
				    <span class="titleVal">{{ userInfo.superUserId || '***' }}</span>
				  </template>
			  </van-cell>
			  <div class="lineBox">
				<div class="coinLine">
					<i class="coinIcon"></i>
					<p>{{ $t('personal.platCoin') }}：{{ userInfo.platCoin || 0 }}</p>
					<!-- <div class="rechargeBtn" @click="handleRecharge">{{ $t('pay.recharge') }}</div> -->
				</div>
			  </div>
		</div>
		
		<div class="footDesc">
			<van-cell-group :border="false">
			  <van-cell :title="$t('footer.useManual')" is-link to="/infos" />
			</van-cell-group>
		</div>
		
		<!-- 登录 -->
		<Login :showLogin="openLogin" @loginSuccess="loginSuccess" />
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,onBeforeMount,ref,watch,computed } from 'vue'
	import { useRouter } from 'vue-router'
	import useUserStore from '@/store/modules/user'
	import useConfigStore from '@/store/modules/config'
	import Login from '@/components/login'
	import { propUserLogin,getVipInfo } from '@/api/gm.js'
	
	const { proxy } = getCurrentInstance();
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	const userInfo = computed(() => {
		return useUserStore().userInfo
	})
	const router = useRouter();
	const openLogin = ref(false)
	const vipLevel = ref('VIP')
	const handleLogin = () => {
		openLogin.value = true
	}
	const loginSuccess = () => {
		getVipInfo(userInfo.value.superUserId).then((res)=>{
			vipLevel.value = res.data.vipLevel
		})
		openLogin.value = false
	}
	const handleLogout = () => {
		useUserStore().close().then(() => {
			vipLevel.value = 'VIP'
			proxy.$toast('Success')
		})
	}
	const handleRecharge = () => {
		router.push({ path:'/recharge' });
	}
	const imgError = () => {
		var img = event.srcElement;
		img.src = require('@/assets/images/common/avatar_temp.png');
		img.onerror = null;
	}
	const toVipDetail = () =>{
		console.log(1111);
	}
	onMounted(() => {
		const query = router.currentRoute.value.query
		if(query.token){
			propUserLogin(query.token).then((res) => {
				console.log(res)
				useUserStore().setInfo(res.data).then((datas) => {
					// logEvent(analytics, 'login_gm', { des: '盒子登录' })
					proxy.$toast('Success')
					
				})
			})
		}
		if(userInfo.value.picture){
			getVipInfo(userInfo.value.superUserId).then((res)=>{
				vipLevel.value = res.data.vipLevel
			})
		}
		
	})
	watch(isMobile, (newVal, oldVal) => {
		if(!newVal){
			router.push({ path:'/' });
		}
	},{ immediate: true })
</script>


<style lang="less" scoped>
	a{
		color: #000;
	  &::after{
	      content: '';display: block;width: 0;height: 2px;background: #51bac0;position: absolute;bottom: 0;left: 50%;transition: all 0.3s ease-in-out;
	  }
	  &:hover{
	      color: #51bac0;
	      &::after{width: 100%;left: 0;}
	    }
	  &.router-link-active{
	      color: #51bac0;
	      &:hover{color: #000000;}
	    }
	  
	}
</style>
<style lang="less" scoped>
	.personBox{
		background: url('~@/assets/images/common/personal_up_bg.png') no-repeat top;
		background-size: 100%;height: calc(100% - 40px);overflow-y: scroll;
		padding: 10rem 1rem 0;box-sizing: border-box;position: relative;
		.footDesc{
			background: #fff;width: calc(100% + 2rem);margin-left: -1rem;margin-top: 2rem;
			height: calc(100vh - 460px);overflow-y: scroll;min-height: 6.25rem;padding-bottom: 3.125rem;box-sizing: border-box;
		}
		.iconBtn{
			position: absolute;top: 1.25rem;right: 1rem;color: #fff;font-size: 0.9375rem;
		}
		.cardBox{
			width: 100%;border-radius: 0.5rem;background: #fff;position: relative;
			padding: 1.875rem 0 0;
			box-shadow: 0px 12px 18px 0px rgba(218, 218, 219, 0.80);
			.avatar{
				position: absolute;left: 1.875rem;top: -2rem;z-index: 111;
				width: 4rem;height: 4rem;border: 0.125rem solid #fff;border-radius: 50%;overflow: hidden;
				img{
					width: 100%;
				}
			}
			.vipIcon{
				width: 4.2rem;
				height: 1.6rem;
				text-align: center;
				line-height:1.6rem;
				padding-left: 1.2rem;
				color: #f4f4f4;
				font-size: 0.8rem;
				position: absolute;
				left: 28%;
				top: 1%;
				background: url('~@/assets/images/common/vipLevelIcon.png') no-repeat center;
				background-size: 100%;
			}
			.vipDetail{
				font-size: 0.8rem;
				position: absolute;
				right:2%;
				line-height: 1.5rem;
				top: 2%;
				color: #000;
			}
			.lineBox{
				padding: 0 1rem 1rem;box-sizing: border-box;margin: 0.75rem 0;
			}
			.coinLine{
				display: flex;align-items: center;justify-content: center;
				padding: 0.75rem 0.75rem;
				background: url('~@/assets/images/common/line_bg.png') no-repeat center;
				background-size: 100%;
				.coinIcon{
					display: inline-block;width: 1.25rem;height: 1.25rem;
					background: url('~@/assets/images/common/coin_icon.png') no-repeat center;
					background-size: 100%;flex-shrink: 0;margin-right: 0.3125rem;
				}
				p{
					flex: 1;font-size: 0.875rem;
				}
				.rechargeBtn{
					flex-shrink: 0;
					font-size: 0.875rem;
					background: linear-gradient(to right,#51BAC0,#85D6DA);color: #fff;
					padding: 0.25rem 1.25rem;border-radius: 0.3125rem;
				}
			}
			/deep/.van-cell:after{
				border-bottom: none;
			}
		}
	}
</style>